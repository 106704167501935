.page ul {
  list-style-type: none;
  display: flex;
  align-items: center;
}

.page .selected {
  color: #5c696e;
  font-weight: 600;
}

.page li {
  color: #c4c9cb;
}

.page .previous {
  color: #5c696e;
  font-weight: 600;
  padding-top: 2.5px;
}

.page .next {
  color: #5c696e;
  font-weight: 600;
  padding-top: 2.5px;
}

.page li {
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
}
