@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: EuclidCircularB, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: #07000f;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.active {
  background-color: #2db49e23;
}
.active i {
  color: #006355;
}
.active span {
  color: #07000f;
}
.item-header-large {
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  color: #07000f;
}
.item-header {
  font-size: 17px;
  font-weight: 600;
  line-height: 32px;
}
.active-border {
  font-weight: 600;
  color: #07000f;
  border-left: 4px solid #07000f;
}
.inactive {
  font-weight: 400;
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
  color: #4a5361;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-selector:hover,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #07000f !important;
  font-weight: 500;
  font-size: 14px;
}
.ant-tabs-tab-btn:hover {
  color: #006355 !important;
}

:where(.css-dev-only-do-not-override-pr0fja).ant-tabs .ant-tabs-ink-bar {
  position: absolute;
  background: #006355 !important;
  pointer-events: none;
}
